import { CbWorkstationType } from '@npm/data-access';

import { useFeatureFlags } from '../app/featureFlags/featureFlags.context';
import { usePermissions } from '../auth/permissions/usePermissions';
import {
  getWorkstationFromPathname,
  useUserContextStore,
} from '../auth/user/context';

export const useAreNotificationsEnabled = () => {
  const { isEnabled } = useFeatureFlags();

  const isIndividual = useUserContextStore(
    state => state.isIndividualNpmsInvestor
  );

  const workstationFromPathname = getWorkstationFromPathname(
    window.location.pathname
  );
  const { BRO_READ_ONLY, BRO_ACCOUNT_MANAGER } = usePermissions();

  return (
    (workstationFromPathname === CbWorkstationType.items.brokerage ||
      (workstationFromPathname === CbWorkstationType.items.investor &&
        isIndividual &&
        isEnabled({
          type: 'allow-if-enabled',
          flag: 'NOTIFICATIONS',
        }))) &&
    !BRO_READ_ONLY &&
    !BRO_ACCOUNT_MANAGER
  );
};
