import {
  type QueryObserverOptions,
  QueryCache,
  QueryClient,
} from '@tanstack/react-query';

import { type MutationKey, type QueryKey } from '../generated/hooks';

import {
  handleAddActOnNegotiationAction,
  handleAddCreateAccountAction,
  handleAddCreateNegotiationAction,
  handleAddCreateSubmissionAction,
  handleAddEnterOrderAction,
  handleAddSmHoldingAction,
  handleAddUpdateOrderAction,
} from './datadog';
import {
  SM_NEGOTIATION_QUERY_KEYS,
  SM_ORDER_QUERY_KEYS,
  SUBMISSION_QUERY_KEYS,
} from './react-query.constants';

export const queryCache = new QueryCache();

export const queryClient = new QueryClient({
  queryCache,
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
      refetchOnMount: true, // default: true
      refetchOnReconnect: false, // default: true
      retry: false, // default: 3
      staleTime: 60 * 1000, // default: 0
    },
  },
});

export const getQueryClient = () => queryClient;

export const apiQueryConfig: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key in QueryKey]?: QueryObserverOptions<any, any>;
} = {
  Codebook: { cacheTime: Infinity, staleTime: Infinity },
  CodebookPost: { cacheTime: Infinity, staleTime: Infinity },
  DocumentDownload: { cacheTime: 0, staleTime: 0 },
  FeatureFlagsIndex: { cacheTime: 0, staleTime: 0 },
};

/* Configuration for mutations
   Properties:
    - invalidateQueries: list of QueryKeys that has to be invalidated after successful mutation execution
*/
export const apiMutationConfig: {
  [key in MutationKey]?: {
    invalidateQueries?: QueryKey[];
    handleDatadogAction?: (payload: unknown, res: unknown) => void;
  };
} = {
  // holdings
  IssuerEntityHoldingCreate: {
    handleDatadogAction: handleAddSmHoldingAction,
  },
  HoldingUpdate: {
    invalidateQueries: [
      'HoldingIndex',
      'HoldingShow',
      'ActionCounterShow',
      'SubmissionIndex',
      'SecondmarketSubmissionIndex',
    ],
  },
  HoldingStatusUpdate: {
    invalidateQueries: ['HoldingIndex', 'ActionCounterShow', 'MatchShow'],
  },
  HoldingDestroy: { invalidateQueries: ['HoldingIndex', 'ActionCounterShow'] },

  // submission
  EventSubmissionCreate: {
    invalidateQueries: [...SUBMISSION_QUERY_KEYS],
    handleDatadogAction: handleAddCreateSubmissionAction,
  },
  EventSubmissionUpdate: {
    invalidateQueries: [...SUBMISSION_QUERY_KEYS],
  },
  EventSubmissionDelete: {
    invalidateQueries: ['SubmissionIndex', 'EventSubmissionIndex', 'EventList'],
  },
  EventSubmissionStepStatusComplete: {
    invalidateQueries: [
      'EventSubmissionStepStatus',
      'EventList',
      ...SUBMISSION_QUERY_KEYS,
    ],
  },
  OrderItemDestroy: {
    invalidateQueries: [
      'SubmissionIndex',
      'SecondmarketSubmissionIndex',
      'EventSubmissionIndex',
      'HoldingIndex',
    ],
  },

  // PreferredBuyer
  AccountPreferredBuyerCreate: {
    invalidateQueries: ['WorkstationAccountIndex'],
  },

  PreferredBuyerDestroy: {
    invalidateQueries: ['WorkstationAccountIndex'],
  },

  PreferredBuyerCreate: {
    invalidateQueries: ['WorkstationAccountIndex'],
  },

  // buyer survey
  BuyerSurveyCreate: {
    invalidateQueries: ['BuyerSurveyShow', 'AccountOnboardingStatusShow'],
  },
  BuyerSurveyUpdate: {
    invalidateQueries: ['BuyerSurveyShow', 'AccountOnboardingStatusShow'],
  },

  // workstation
  // see invalidateWorkstationCache in WorkstationSwitcher.utils.ts
  // WorkstationCreate: { invalidateQueries: ['UserRoleIndex'] },

  // user
  UserUpdate: { invalidateQueries: ['UserShowCurrent'] },

  // person
  PersonUpdate: {
    invalidateQueries: [
      'PersonShow',
      'AccountShow',
      'UserShowCurrent',
      'AccountOnboardingStatusShow',
    ],
  },

  // organization
  OrganizationContactInformationCreate: {
    invalidateQueries: ['OrganizationShow', 'AccountShow'],
  },
  OrganizationContactInformationUpdate: {
    invalidateQueries: ['OrganizationShow', 'AccountShow'],
  },
  OrganizationUpdate: {
    invalidateQueries: [
      'OrganizationShow',
      'AccountShow',
      'AccountOnboardingStatusShow',
      'BackgroundCheckShowLatest',
      'SubmissionIndex',
      'SecondmarketSubmissionIndex',
      'SubmissionShow',
      'AccountShow',
    ],
  },

  // account
  AccountCreate: {
    invalidateQueries: [
      'UserShowCurrent',
      'AccountIndex',
      'UserRoleIndex',
      'BrokerageFirmIndex',
    ],
    handleDatadogAction: handleAddCreateAccountAction,
  },
  AccountUpdate: {
    invalidateQueries: [
      'AccountShow',
      'AccountOnboardingStatusShow',
      'BackgroundCheckShowLatest',
      'SubmissionIndex',
      'SecondmarketSubmissionIndex',
      'SubmissionShow',
      'AccountIndex',
    ],
  },
  AccountPaymentInformationCreate: {
    invalidateQueries: [
      'AccountPaymentInformationIndex',
      'AccountOnboardingStatusShow',
    ],
  },
  AccountSignerCreate: {
    invalidateQueries: [
      'AccountSignerIndex',
      'PersonShow',
      'AccountOnboardingStatusShow',
    ],
  },
  AccountSpouseUpdate: {
    invalidateQueries: [
      'AccountSpouseShow',
      'AccountShow',
      'PersonShow',
      'AccountOnboardingStatusShow',
    ],
  },
  AccountSignerUpdate: {
    invalidateQueries: ['AccountSignerIndex', 'AccountSignerShow'],
  },
  AccountSignerDelete: { invalidateQueries: ['AccountSignerIndex'] },

  // entity account accreditation
  EntityAccountAccreditationCreate: {
    invalidateQueries: [
      'EntityAccountAccreditationShow',
      'AccountOnboardingStatusShow',
      'AccountShow',
      'AccountIndex',
    ],
  },
  EntityAccountAccreditationDelete: {
    invalidateQueries: [
      'EntityAccountAccreditationShow',
      'AccountOnboardingStatusShow',
      'AccountShow',
      'AccountIndex',
    ],
  },
  EntityAccountAccreditationUpdate: {
    invalidateQueries: [
      'EntityAccountAccreditationShow',
      'AccountShow',
      'AccountIndex',
    ],
  },
  // person accreditation
  PersonAccreditationCreate: {
    invalidateQueries: [
      'PersonAccreditationShow',
      'AccountOnboardingStatusShow',
      'AccountShow',
    ],
  },
  PersonAccreditationDelete: {
    invalidateQueries: ['PersonAccreditationShow', 'AccountShow'],
  },

  // background check
  BackgroundCheckUpdate: {
    invalidateQueries: [
      'AccountOnboardingStatusShow',
      'SubmissionIndex',
      'SecondmarketSubmissionIndex',
      'SubmissionShow',
      'BackgroundCheckShowLatest',
      'AccountShow',
    ],
  },

  // documents
  DocumentCreate: {
    invalidateQueries: [
      'AccountBeneficialOwnersIndex',
      'AgreementIndex',
      'ActionCounterShow',
      'AgreementHistoryIndex',
      'FolderIndex',
      'FolderShow',
      'AccountOnboardingStatusShow',
      'DocumentPreview',
      'DocumentShow',
      'PersonShow',
      'AccountOnboardingAgreementShow',
    ],
  },
  DocumentUpdate: {
    invalidateQueries: [
      'FolderIndex',
      'FolderShow',
      'DocumentPreview',
      'DocumentShow',
    ],
  },
  DocumentDestroy: {
    invalidateQueries: ['FolderIndex', 'FolderShow'],
  },
  FolderCreate: {
    invalidateQueries: ['FolderIndex', 'FolderShow'],
  },
  FolderDestroy: {
    invalidateQueries: ['FolderIndex', 'FolderShow'],
  },
  FolderUpdate: {
    invalidateQueries: ['FolderIndex', 'FolderShow'],
  },

  // Match detail
  MatchRofrAssignCreate: {
    invalidateQueries: [
      'MatchShow',
      'MatchIndex',
      'IssuerEntityMatchIndex',
      'ActionCounterShow',
    ],
  },

  // NDA
  NdaUserCreate: {
    invalidateQueries: [
      'EventList',
      'NdaIndex',
      'ActionCounterShow',
      'EventSubmissionIndex',
    ],
  },

  // User Role
  UserRoleCreate: {
    invalidateQueries: ['AccountShow'],
  },
  UserRoleDestroy: {
    invalidateQueries: ['AccountShow'],
  },
  UserRoleUpdate: {
    invalidateQueries: ['AccountShow'],
  },

  // Beneficial Ownership
  AccountBeneficialOwnerCreate: {
    invalidateQueries: ['AccountBeneficialOwnersIndex'],
  },
  AccountBeneficialOwnerDestroy: {
    invalidateQueries: ['AccountBeneficialOwnersIndex'],
  },
  AccountBeneficialOwnershipAssessmentDestroy: {
    invalidateQueries: [
      'AccountBeneficialOwnersIndex',
      'AccountBeneficialOwnershipAssessmentShow',
      'AccountOnboardingStatusShow',
    ],
  },
  AccountBeneficialOwnershipAssessmentUpdate: {
    invalidateQueries: [
      'AccountBeneficialOwnershipAssessmentShow',
      'AccountOnboardingStatusShow',
      'AccountBeneficialOwnersIndex',
    ],
  },

  // List of Entities
  BrokerageFirmAccountCreate: {
    invalidateQueries: [
      'BrokerageFirmAccountIndex',
      'BrokerageFirmAccountStatisticShow',
    ],
  },

  NegotiationUpdate: {
    invalidateQueries: [...SM_ORDER_QUERY_KEYS, ...SM_NEGOTIATION_QUERY_KEYS],
    handleDatadogAction: handleAddActOnNegotiationAction,
  },

  NegotiationCreate: {
    invalidateQueries: [...SM_ORDER_QUERY_KEYS, ...SM_NEGOTIATION_QUERY_KEYS],
    handleDatadogAction: handleAddCreateNegotiationAction,
  },

  // Sign Agreement
  AgreementSigning: {
    invalidateQueries: ['ActionCounterShow'],
  },

  /* --------- Second Market --------- */

  // SecondMarket Orders
  SecondmarketSubmissionCreate: {
    invalidateQueries: ['MatchIndex', 'HoldingIndex', ...SM_ORDER_QUERY_KEYS],
    handleDatadogAction: handleAddEnterOrderAction,
  },

  SecondmarketOrderItemCancel: {
    invalidateQueries: ['HoldingIndex', ...SM_ORDER_QUERY_KEYS],
  },
  SecondmarketOrderItemUpdate: {
    invalidateQueries: [...SM_ORDER_QUERY_KEYS],
    handleDatadogAction: handleAddUpdateOrderAction,
  },

  // SecondMarket Watchlist
  WatchlistCreate: {
    invalidateQueries: [
      'WatchlistIndex',
      'EntityInvestorPortfolioIndex',
      'IssuerEntityIndex',
    ],
  },
  WatchlistUpdate: {
    invalidateQueries: ['WatchlistIndex', 'WatchlistShow'],
  },
  WatchlistDestroy: {
    invalidateQueries: ['WatchlistIndex'],
  },
  NotificationBulkUpdate: {
    invalidateQueries: ['NotificationIndex'],
  },
  NotificationUpdate: {
    invalidateQueries: ['NotificationIndex'],
  },
};
